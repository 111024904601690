import { AppProps } from '@/components/Layout/PageWrapper';
import { SpeedInsights } from '@vercel/speed-insights/next';
import type { IncomingMessage } from 'http';
import type { AppContextType } from 'next/dist/shared/lib/utils';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'styles/globals.css';
export { reportWebVitals } from 'next-axiom';
function MyApp(props: AppProps) {
  const { Component, pageProps } = props;

  if (Component.PageWrapper !== undefined) return Component.PageWrapper(props);
  return (
    <>
      <Component {...pageProps} />
      <SpeedInsights />
    </>
  );
}

declare global {
  interface Window {
    exploreNewLocale: string;
  }
}

MyApp.getInitialProps = async (ctx: AppContextType) => {
  const { req } = ctx.ctx;

  let newLocale = 'en';

  if (req) {
    const { getLocale } = await import('@/lib/getLocale');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newLocale = await getLocale(
      req as IncomingMessage & { cookies: Record<string, any> }
    );
  } else if (typeof window !== 'undefined' && window.exploreNewLocale) {
    newLocale = window.exploreNewLocale;
  }

  return {
    pageProps: {
      newLocale,
    },
  };
};

export default MyApp;
